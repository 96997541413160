import { Navigate, useRoutes } from 'react-router-dom';
// context
import { BusinessTagsProvider } from '../contexts/BusinessTagsContext';
import { VendorProvider } from '../contexts/VendorContext';
import { EventTagsProvider } from '../contexts/EventTagsContext';
import { CampaignPackageProvider } from '../contexts/CampaignPackageContext';
import { CampaignCreditsProvider } from '../contexts/CampaignCreditsContext';
import { CampaignTemplateProvider } from '../contexts/CampaignTemplateContext';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  Page404,
  LoginPage,
  Dashboard,
  VendorsList,
  CreateEditVendor,
  UpdateVendor,
  BusinessTagsList,
  CreateEditBusinessTags,
  EventTagsList,
  CreateEditEventTags,
  CancelTickets,
  AddCancelTickets,
  TicketOrders,
  SponsoredEvents,
  CreateEvents,
  Customer,
  CreditsPackageList,
  CreateEditCreditPackage,
  AddCreditsList,
  AddTemplateList,
  AddCampaignCredits,
  AddCampaignTemplate,
  Maintenance,
  // Maintenance,
  // PackagesList,
  // CreateEditpackages,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          children: [
            {
              path: 'dashboard',
              children: [{ element: <Dashboard />, index: true }],
            },
          ],
        },
        {
          element: <BusinessTagsProvider />,
          children: [
            {
              path: 'vendors',
              element: <VendorProvider />,
              children: [
                {
                  children: [
                    { element: <VendorsList />, index: true },
                    { path: 'create', element: <CreateEditVendor /> },
                    { path: 'update/:id', element: <UpdateVendor /> },
                  ],
                },
              ],
            },

            {
              path: 'business-tags',
              children: [
                { element: <BusinessTagsList />, index: true },
                { path: 'create', element: <CreateEditBusinessTags /> },
                { path: 'edit/:name', element: <CreateEditBusinessTags edit /> },
              ],
            },
          ],
        },
        // {
        //   path: 'packages',
        //   children: [
        //     { element: <Navigate to="/dashboard/packages/packages-list" replace />, index: true },
        //     { path: 'packages-list', element: <PackagesList /> },
        //     { path: 'create-edit-packages', element: <CreateEditpackages /> },
        //   ],
        // },
        {
          path: 'event-tags',
          children: [
            {
              element: <EventTagsProvider />,
              children: [
                {
                  element: <EventTagsList />,
                  index: true,
                },
                { path: 'create', element: <CreateEditEventTags /> },
                { path: 'edit/:name', element: <CreateEditEventTags edit /> },
              ],
            },
          ],
        },
        {
          path: 'ticket-orders',
          element: <VendorProvider />,
          children: [
            {
              element: <TicketOrders />,
              index: true,
            },
          ],
        },
        {
          path: 'sponsored-events',
          element: <VendorProvider />,
          children: [
            {
              element: <SponsoredEvents />,
              index: true,
            },
            { path: 'create', element: <CreateEvents /> },
          ],
        },

        {
          path: 'cancel-tickets',
          element: <VendorProvider />,
          children: [
            {
              element: <CancelTickets />,
              index: true,
            },
            { path: 'create', element: <AddCancelTickets /> },
          ],
        },
        {
          path: 'customer',
          children: [
            {
              element: <Customer />,
              index: true,
            },
          ],
        },
        {
          path: 'campaign',
          element: <VendorProvider />,
          children: [
            {
              path: 'package',
              element: <CampaignPackageProvider />,
              children: [
                {
                  element: <CreditsPackageList />,
                  index: true,
                },

                {
                  element: <CreateEditCreditPackage />,
                  path: 'create',
                },
                { path: 'edit/:name', element: <CreateEditCreditPackage edit /> },
              ],
            },
            {
              path: 'template',
              element: <CampaignTemplateProvider />,
              children: [
                {
                  element: <AddTemplateList />,
                  index: true,
                },

                {
                  element: <AddCampaignTemplate />,
                  path: 'add',
                },
                { path: 'edit/:name', element: <CreateEditCreditPackage edit /> },
              ],
            },
            {
              path: 'credits',
              element: <CampaignCreditsProvider />,
              children: [
                {
                  element: <AddCreditsList />,
                  index: true,
                },

                {
                  element: <AddCampaignCredits />,
                  path: 'add',
                },
                { path: 'edit/:name', element: <AddCampaignCredits edit /> },
              ],
            },

            // { path: 'create', element: <CreateEvents /> },
          ],
        },
        {
          path: 'maintenance',
          children: [
            {
              element: <Maintenance />,
              index: true,
            },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
