import { createContext, useEffect, useContext, useCallback, useReducer, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthContext } from '../auth/useAuthContext';

// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';

// ----------------------------------------------------------------------

const initialState = {
  campaign_packages: [],
  page: 0,
  search: '',
  totalPages: 1,
  count: 0,
  error: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      ...state,
      campaign_packages: action.payload.campaign_packages,
      page: action.payload.page,
      totalPages: action.payload.totalPages,
      count: action.payload._count,
    };
  }
  if (action.type === 'ERROR') {
    return {
      ...state,
      error: true,
    };
  }
  if (action.type === 'PAGE_CHANGE') {
    return {
      ...state,
      page: action.payload.page,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const CampaignPackageContext = createContext(initialState);

export const useCampaignPackageContext = () => {
  const context = useContext(CampaignPackageContext);

  if (!context) throw new Error('useSettingsContext must be use inside SettingsProvider');

  return context;
};

export function CampaignPackageProvider() {
  const { user, logout } = useAuthContext();
  const storageAvailable = localStorageAvailable();

  const [state, dispatch] = useReducer(reducer, initialState);

  const getCampaignPackages = useCallback(
    async (vendor_id, search, page = 0, page_size) => {
      try {
        const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: '/service/accounts_service/v1/credits/package/list',
          headers: {
            authorization: `Bearer ${accessToken}`,
            user,
            'Content-Type': 'application/json',
          },
          params: { vendor_id, search, page, page_size },
        };
        const response = await axios.request(config);
        const { _payload, currentPage, totalPages } = response.data;
        dispatch({
          type: 'INITIAL',
          payload: {
            campaign_packages: _payload,
            page: currentPage,
            totalPages,
          },
        });
      } catch (error) {
        dispatch({
          type: 'ERROR',
        });
        if (error === 'Unauthorized request') {
          logout();
        }
      }
    },
    [storageAvailable, user, logout]
  );

  useEffect(() => {
    getCampaignPackages();
  }, [getCampaignPackages]);

  const pageChange = useCallback(
    (vendor_id, search, page) => {
      getCampaignPackages(vendor_id, search, page);
    },
    [getCampaignPackages]
  );
  const searchPackage = useCallback(
    (search, vendor_id = '') => {
      getCampaignPackages(vendor_id, search);
    },
    [getCampaignPackages]
  );
  const filterByVendor = useCallback(
    (vendor_id) => {
      getCampaignPackages(vendor_id);
    },
    [getCampaignPackages]
  );

  const createCampaignPackage = useCallback(
    async (data) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/service/accounts_service/v1/credits/package',
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
        data,
      };
      await axios.request(config);
      getCampaignPackages();
    },
    [getCampaignPackages, storageAvailable, user]
  );

  const updateCampaignPackages = useCallback(
    async (id, data) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `/service/accounts_service/v1/credits/package/${id}`,
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
        data,
      };
      await axios.request(config);
      getCampaignPackages();
    },
    [getCampaignPackages, storageAvailable, user]
  );

  const getVendorBankInfo = useCallback(
    async (id) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `/system/partners/${id}/bankacc`,
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.request(config);
      getCampaignPackages();
      return response.data;
    },
    [getCampaignPackages, storageAvailable, user]
  );

  const memoizedValue = useMemo(
    () => ({
      campaign_packages: state.campaign_packages,
      page: state.page,
      search: state.search,
      totalPages: state.totalPages,
      createCampaignPackage,
      updateCampaignPackages,
      getVendorBankInfo,
      pageChange,
      searchPackage,
      filterByVendor,
      getCampaignPackages,
    }),
    [
      state.campaign_packages,
      state.page,
      state.totalPages,
      state.search,
      createCampaignPackage,
      updateCampaignPackages,
      getVendorBankInfo,
      pageChange,
      searchPackage,
      filterByVendor,
      getCampaignPackages,
    ]
  );

  return (
    <CampaignPackageContext.Provider value={memoizedValue}>
      <Outlet />
    </CampaignPackageContext.Provider>
  );
}
