import { createContext, useEffect, useContext, useCallback, useReducer, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthContext } from '../auth/useAuthContext';

// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';

// ----------------------------------------------------------------------

const initialState = {
  eventTags: [],
  error: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      ...state,
      eventTags: action.payload.eventTags,
    };
  }
  if (action.type === 'ERROR') {
    return {
      ...state,
      error: true,
    };
  }
  if (action.type === 'CREATE_TAG') {
    return {
      ...state,
      eventTags: [...state.eventTags, action.payload.eventTag],
    };
  }
  if (action.type === 'UPDATE_TAG') {
    const tags = [...state.eventTags];
    const index = tags.findIndex((tag) => tag._id === action.payload.eventTag._id);
    tags[index] = action.payload.eventTag;
    return {
      ...state,
      eventTags: tags,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const EventTagsContext = createContext(initialState);

export const useEventTagsContext = () => {
  const context = useContext(EventTagsContext);

  if (!context) throw new Error('useSettingsContext must be use inside SettingsProvider');

  return context;
};

export function EventTagsProvider() {
  const { user, logout } = useAuthContext();
  const storageAvailable = localStorageAvailable();

  const [state, dispatch] = useReducer(reducer, initialState);

  const getEventTags = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: '/service/events_service/v1/events/tags',
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.request(config);
      dispatch({
        type: 'INITIAL',
        payload: {
          eventTags: response.data,
        },
      });
    } catch (error) {
      if (error === 'Unauthorized request') {
        logout();
      }
      console.log(error);
      dispatch({
        type: 'ERROR',
      });
    }
  }, [user, storageAvailable, logout]);

  useEffect(() => {
    getEventTags();
  }, [getEventTags]);

  const createTag = useCallback(
    async (data) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/service/events_service/v1/events/tags',
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
        data,
      };
      const response = await axios.request(config);
      dispatch({
        type: 'CREATE_TAG',
        payload: {
          eventTag: response.data,
        },
      });
      return response;
    },
    [storageAvailable, user]
  );

  const updateTag = useCallback(
    async (id, data) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `/service/events_service/v1/events/tags`,
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
        data,
        params: { id },
      };
      const response = await axios.request(config);
      dispatch({
        type: 'UPDATE_TAG',
        payload: {
          eventTag: response.data,
        },
      });
      return response;
    },
    [storageAvailable, user]
  );

  const memoizedValue = useMemo(
    () => ({ eventTags: state.eventTags, createTag, updateTag }),
    [createTag, state.eventTags, updateTag]
  );

  return (
    <EventTagsContext.Provider value={memoizedValue}>
      <Outlet />
    </EventTagsContext.Provider>
  );
}
