import { createContext, useEffect, useContext, useCallback, useReducer, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthContext } from '../auth/useAuthContext';

// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';

// ----------------------------------------------------------------------

const initialState = {
  vendors: [],
  vendor_list: [],
  page: 0,
  totalPages: 1,
  count: 0,
  error: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      ...state,
      vendor_list: action.payload.vendors,
      page: action.payload.page,
      totalPages: action.payload.totalPages,
      count: action.payload._count,
    };
  }
  if (action.type === 'DROPDOWN') {
    return {
      ...state,
      vendors: action.payload.vendors,
    };
  }
  if (action.type === 'ERROR') {
    return {
      ...state,
      error: true,
    };
  }
  if (action.type === 'PAGE_CHANGE') {
    return {
      ...state,
      page: action.payload.page,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const VendorContext = createContext(initialState);

export const useVendorContext = () => {
  const context = useContext(VendorContext);

  if (!context) throw new Error('useSettingsContext must be use inside SettingsProvider');

  return context;
};

export function VendorProvider() {
  const { user, logout } = useAuthContext();
  const storageAvailable = localStorageAvailable();

  const [state, dispatch] = useReducer(reducer, initialState);

  const getVendor = useCallback(
    async (page = 0) => {
      const pagination = false;
      try {
        const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: '/system/partners',
          headers: {
            authorization: `Bearer ${accessToken}`,
            user,
            'Content-Type': 'application/json',
          },
          params: { type: 'Vendor', page, pagination },
        };
        const response = await axios.request(config);
        const { payload, currentPage, totalPages } = response.data;
        dispatch({
          type: 'DROPDOWN',
          payload: {
            vendors: payload,
          },
        });
      } catch (error) {
        dispatch({
          type: 'ERROR',
        });
        if (error === 'Unauthorized request') {
          logout();
        }
      }
    },
    [storageAvailable, user, logout]
  );

  const getVendorList = useCallback(
    async (page = 0) => {
      try {
        const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: '/system/partners',
          headers: {
            authorization: `Bearer ${accessToken}`,
            user,
            'Content-Type': 'application/json',
          },
          params: { type: 'Vendor', page },
        };
        const response = await axios.request(config);
        const { payload, currentPage, totalPages } = response.data;
        dispatch({
          type: 'INITIAL',
          payload: {
            vendors: payload,
            page: currentPage,
            totalPages,
          },
        });
      } catch (error) {
        dispatch({
          type: 'ERROR',
        });
        if (error === 'Unauthorized request') {
          logout();
        }
      }
    },
    [storageAvailable, user, logout]
  );

  useEffect(() => {
    getVendorList();
  }, [getVendorList]);
  useEffect(() => {
    getVendor();
  }, [getVendor]);

  const pageChange = useCallback(
    (page) => {
      getVendorList(page);
    },
    [getVendorList]
  );

  const createVendor = useCallback(
    async (data) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/system/partners/onboard',
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
        data,
      };
      await axios.request(config);
      getVendorList();
    },
    [getVendorList, storageAvailable, user]
  );

  const getVendorBasicInfo = useCallback(
    async (id) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `/system/partners/${id}/information`,
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.request(config);
      getVendorList();
      return response.data;
    },
    [getVendorList, storageAvailable, user]
  );

  const updateVendorInfo = useCallback(
    async (id, data) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `/system/partners/${id}/information`,
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
        data,
      };

      await axios.request(config);

      getVendorList();
    },
    [getVendorList, storageAvailable, user]
  );

  const updateVendorDocs = useCallback(
    async (id, data) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `/system/partners/${id}/document`,
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
        data,
      };
      await axios.request(config);
      getVendorList();
    },
    [getVendorList, storageAvailable, user]
  );

  const getVendorBankInfo = useCallback(
    async (id) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `/system/partners/${id}/bankacc`,
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.request(config);
      getVendorList();
      return response.data;
    },
    [getVendorList, storageAvailable, user]
  );

  const updateVendorBankInfo = useCallback(
    async (id, data) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `/system/partners/${id}/bank`,
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
        data,
      };
      await axios.request(config);
      getVendorList();
    },
    [getVendorList, storageAvailable, user]
  );

  const updateConfig = useCallback(
    async (id, data) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `/service/accounts_service/v1/accounts/vendor_config/${id}`,

        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
        data: { config_list: data },
      };
      const response = await axios.request(config);
      return response;
    },
    [storageAvailable, user]
  );
  const updateFeature = useCallback(
    async (id, data) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `/system/partners/${id}/features`,
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
        data,
      };
      const response = await axios.request(config);
      return response;
    },
    [storageAvailable, user]
  );

  const getAllServices = useCallback(
    async (id) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `/system/services`,
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.request(config);
      return response.data;
    },
    [storageAvailable, user]
  );

  const memoizedValue = useMemo(
    () => ({
      vendors: state.vendors,
      vendor_list: state.vendor_list,
      page: state.page,
      totalPages: state.totalPages,
      createVendor,
      getVendorBasicInfo,
      updateVendorDocs,
      updateVendorInfo,
      getVendorBankInfo,
      updateVendorBankInfo,
      getAllServices,
      pageChange,
      getVendorList,
      updateConfig,
      updateFeature,
    }),
    [
      state.vendors,
      state.page,
      state.vendor_list,
      state.totalPages,
      createVendor,
      getVendorBasicInfo,
      updateVendorDocs,
      updateVendorInfo,
      getVendorBankInfo,
      updateVendorBankInfo,
      getAllServices,
      pageChange,
      getVendorList,
      updateConfig,
      updateFeature,
    ]
  );

  return (
    <VendorContext.Provider value={memoizedValue}>
      <Outlet />
    </VendorContext.Provider>
  );
}
