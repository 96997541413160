import { createContext, useEffect, useContext, useCallback, useReducer, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthContext } from '../auth/useAuthContext';

// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';

// ----------------------------------------------------------------------

const initialState = {
  campaign_credits: [],
  page: 0,
  search: '',
  totalPages: 1,
  count: 0,
  error: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      ...state,
      campaign_credits: action.payload.campaign_credits,
      page: action.payload.page,
      totalPages: action.payload.totalPages,
      count: action.payload._count,
    };
  }
  if (action.type === 'ERROR') {
    return {
      ...state,
      error: true,
    };
  }
  if (action.type === 'PAGE_CHANGE') {
    return {
      ...state,
      page: action.payload.page,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const CampaignCreditsContext = createContext(initialState);

export const useCampaignCreditsContext = () => {
  const context = useContext(CampaignCreditsContext);

  if (!context)
    throw new Error('useCampaignCreditsContext must be use inside  CampaignCreditsProvider');

  return context;
};

export function CampaignCreditsProvider() {
  const { user, logout } = useAuthContext();
  const storageAvailable = localStorageAvailable();

  const [state, dispatch] = useReducer(reducer, initialState);

  const getCampaignCredits = useCallback(
    async (vendor_id, search, page = 0, page_size) => {
      try {
        const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: '/service/accounts_service/v1/credits/orders',
          headers: {
            authorization: `Bearer ${accessToken}`,
            user,
            'Content-Type': 'application/json',
          },
          params: { vendor_id, search, page, page_size },
        };
        const response = await axios.request(config);
        const { _payload, currentPage, totalPages } = response.data;
        dispatch({
          type: 'INITIAL',
          payload: {
            campaign_credits: _payload,
            page: currentPage,
            totalPages,
          },
        });
      } catch (error) {
        dispatch({
          type: 'ERROR',
        });
        if (error === 'Unauthorized request') {
          logout();
        }
      }
    },
    [storageAvailable, user, logout]
  );

  useEffect(() => {
    getCampaignCredits();
  }, [getCampaignCredits]);

  const pageChange = useCallback(
    (vendor_id, search, page) => {
      getCampaignCredits(vendor_id, search, page);
    },
    [getCampaignCredits]
  );
  const searchPackage = useCallback(
    (search, vendor_id = '') => {
      getCampaignCredits(vendor_id, search);
    },
    [getCampaignCredits]
  );
  const filterByVendor = useCallback(
    (vendor_id) => {
      getCampaignCredits(vendor_id);
    },
    [getCampaignCredits]
  );

  const createCampaignCredits = useCallback(
    async (data) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/service/accounts_service/v1/credits/vendors',
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
        data,
      };
      await axios.request(config);
      getCampaignCredits();
    },
    [getCampaignCredits, storageAvailable, user]
  );

  const updateCampaignCredits = useCallback(
    async (id, data) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `/service/accounts_service/v1/credits/order//${id}`,
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
        data,
      };
      await axios.request(config);
      getCampaignCredits();
    },
    [getCampaignCredits, storageAvailable, user]
  );

  const getVendorBankInfo = useCallback(
    async (id) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `/system/partners/${id}/bankacc`,
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.request(config);
      getCampaignCredits();
      return response.data;
    },
    [getCampaignCredits, storageAvailable, user]
  );

  const memoizedValue = useMemo(
    () => ({
      campaign_credits: state.campaign_credits,
      page: state.page,
      search: state.search,
      totalPages: state.totalPages,
      createCampaignCredits,
      updateCampaignCredits,
      getVendorBankInfo,
      pageChange,
      searchPackage,
      filterByVendor,
      getCampaignCredits,
    }),
    [
      state.campaign_credits,
      state.page,
      state.totalPages,
      state.search,
      createCampaignCredits,
      updateCampaignCredits,
      getVendorBankInfo,
      pageChange,
      searchPackage,
      filterByVendor,
      getCampaignCredits,
    ]
  );

  return (
    <CampaignCreditsContext.Provider value={memoizedValue}>
      <Outlet />
    </CampaignCreditsContext.Provider>
  );
}
