// Import statements
import PropTypes from 'prop-types';
import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Collapse } from '@mui/material';
import useActiveLink from '../../../hooks/useActiveLink';
import NavItem from './NavItem';

// PropTypes definition
NavList.propTypes = {
  data: PropTypes.object,
  depth: PropTypes.number,
  hasChild: PropTypes.bool,
};

// Main component
export default function NavList({ data, depth, hasChild }) {
  const { pathname } = useLocation();
  const { active, isExternalLink } = useActiveLink(data.path);
  const [open, setOpen] = useState(active);
  const dropdownRef = useRef(null);

  // Compute whether any sublist item is active
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const sublistActive = useMemo(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return data.children && data.children.some((child) => useActiveLink(child.path).active);
  }, [data.children]);

  const handleToggle = () => {
    // Only toggle when clicking the parent item
    if (!isExternalLink) {
      setOpen(!open);
    }
  };

  const handleClose = useCallback(() => {
    // Close only if it's not a child link or if the clicked item doesn't have children
    if (!isExternalLink || !data.children || !sublistActive) {
      setOpen(false);
    }
  }, [isExternalLink, data.children, sublistActive]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!dropdownRef.current.contains(event.target)) {
        if (!data.children || !sublistActive) {
          setOpen(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [data.children, isExternalLink, sublistActive]);
  return (
    <>
      <div ref={dropdownRef}>
        <NavItem
          item={data}
          depth={depth}
          open={open}
          active={active}
          isExternalLink={isExternalLink}
          onClick={handleToggle}
          onClose={handleClose}
        />

        {hasChild && (
          <Collapse in={open} unmountOnExit>
            <NavSubList data={data.children} depth={depth} />
          </Collapse>
        )}
      </div>
    </>
  );
}

// PropTypes definition for NavSubList
NavSubList.propTypes = {
  data: PropTypes.array,
  depth: PropTypes.number,
};

// Nested component for handling sub-lists
function NavSubList({ data, depth }) {
  return (
    <>
      {data.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
        />
      ))}
    </>
  );
}